exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-choco-blog-js": () => import("./../../../src/pages/choco-blog.js" /* webpackChunkName: "component---src-pages-choco-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-templates-ad-post-js": () => import("./../../../src/templates/ad-post.js" /* webpackChunkName: "component---src-templates-ad-post-js" */),
  "component---src-templates-ad-tag-list-js": () => import("./../../../src/templates/ad-tag-list.js" /* webpackChunkName: "component---src-templates-ad-tag-list-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-genre-list-js": () => import("./../../../src/templates/genre-list.js" /* webpackChunkName: "component---src-templates-genre-list-js" */),
  "component---src-templates-page-post-js": () => import("./../../../src/templates/page-post.js" /* webpackChunkName: "component---src-templates-page-post-js" */),
  "component---src-templates-tag-list-js": () => import("./../../../src/templates/tag-list.js" /* webpackChunkName: "component---src-templates-tag-list-js" */)
}

